import React from 'react';
import styled, { css } from 'styled-components';
import teamImages from '../data/teamImages';
import breakpoints from '../utils/breakpoints';

const TeamGallery = () => (
  <Wrapper>
    {teamImages.map((innerArray, i) => (
      <RowWrapper isEven={i % 2 === 0}>
        {innerArray.map(image => (
          <>
            <SourceWrapper srcset={image.webp} type="image/webp" />
            <ImageWrapper src={image.jpg} />
          </>
        ))}
      </RowWrapper>
    ))}
  </Wrapper>
);

export default TeamGallery;

const Wrapper = styled.div``;

const RowWrapper = styled.div`
  display: flex;

  ${props =>
    !props.isEven &&
    `
  margin-left: -10%;
  `};
`;

const imageStyles = css`
  display: block;
  object-fit: cover;
  width: 33vw;
  margin: 4px;
  max-height: 20vw;

  ${breakpoints.tablet} {
    width: 20vw;
    margin: 6px;
    max-height: 14vw;
  }
`;

const SourceWrapper = styled.source`
  ${imageStyles}
`;

const ImageWrapper = styled.img`
  ${imageStyles}
`;

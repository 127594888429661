import styled from 'styled-components';
import React from 'react';
import upArrow from '../assets/up_arrow.svg';
import safeScrollTo from '../utils/safeScrollTo';

const UpArrowComponent = () => (
  <UpArrow src={upArrow} onClick={() => safeScrollTo('#header-logo')} />
);

const UpArrow = styled.img`
  position: absolute;
  width: 60px;
  cursor: pointer;
  bottom: 95px;
  right: 25px;
  z-index: 100;
`;

export default UpArrowComponent;

import image1 from './team-images/1.webp';
import image2 from './team-images/2.webp';
import image3 from './team-images/3.webp';
import image4 from './team-images/4.webp';
import image5 from './team-images/5.webp';
import image6 from './team-images/6.webp';
import image7 from './team-images/7.webp';
import image8 from './team-images/8.webp';
import image9 from './team-images/9.webp';
import image10 from './team-images/10.webp';
import image11 from './team-images/11.webp';
import image12 from './team-images/12.webp';
import image13 from './team-images/13.webp';
import image14 from './team-images/14.webp';
import image15 from './team-images/15.webp';
import image16 from './team-images/16.webp';

import image1Jpg from './team-images/1.jpg';
import image2Jpg from './team-images/2.jpg';
import image3Jpg from './team-images/3.jpg';
import image4Jpg from './team-images/4.jpg';
import image5Jpg from './team-images/5.jpg';
import image6Jpg from './team-images/6.jpg';
import image7Jpg from './team-images/7.jpg';
import image8Jpg from './team-images/8.jpg';
import image9Jpg from './team-images/9.jpg';
import image10Jpg from './team-images/10.jpg';
import image11Jpg from './team-images/11.jpg';
import image12Jpg from './team-images/12.jpg';
import image13Jpg from './team-images/13.jpg';
import image14Jpg from './team-images/14.jpg';
import image15Jpg from './team-images/15.jpg';
import image16Jpg from './team-images/16.jpg';

// Images should be in 16:9 ratio and jpg size.
const teamImages = [
  [
    { webp: image1, jpg: image1Jpg },
    { webp: image4, jpg: image4Jpg },
    { webp: image3, jpg: image3Jpg },
    { webp: image8, jpg: image8Jpg },
    { webp: image12, jpg: image12Jpg },
  ],
  [
    { webp: image6, jpg: image6Jpg },
    { webp: image7, jpg: image7Jpg },
    { webp: image2, jpg: image2Jpg },
    { webp: image9, jpg: image9Jpg },
    { webp: image10, jpg: image10Jpg },
    { webp: image16, jpg: image16Jpg },
  ],
  [
    { webp: image11, jpg: image11Jpg },
    { webp: image5, jpg: image5Jpg },
    { webp: image13, jpg: image13Jpg },
    { webp: image14, jpg: image14Jpg },
    { webp: image15, jpg: image15Jpg },
  ],
];

export default teamImages;

import davidFogl from '../assets/people/davidFogl.jpeg';
import tuanPhung from '../assets/people/tuanPhung.jpeg';
import danielPetrasek from '../assets/people/danielPetrasek.jpeg';
import bronislavSvoboda from '../assets/people/bronislavSvoboda.jpeg';
import stepanDoubal from '../assets/people/stepanDoubal.jpeg';
import zbynekKripal from '../assets/people/zbynekKripal.jpeg';

const poeple = [
  {
    id: 0,
    title: 'David Fogl',
    position: 'Founder & CEO',
    description: 'Da Boss, who gets the deals.',
    linkedInUrl: 'https://www.linkedin.com/in/david-fogl-46348042/',
    imageUrl: davidFogl,
    longDescription:
      'I always enjoyed building new tools and prototypes and automating processes and manual work when I need to do something repeatedly. For past years with Continero, I orient more on the analytical and consultancy work style, helping out my colleagues on different projects or other companies in being more effective.',
  },
  {
    id: 1,
    title: 'Daniel Petrásek',
    position: 'Solution Architect & Developer',
    description:
      'Setups processes, propose new technologies and develops just the right stuff you need.',
    linkedInUrl: 'https://www.linkedin.com/in/daniel-petr%C3%A1sek-5a780763/',
    imageUrl: danielPetrasek,
    longDescription:
      'Dan is a Backend developer who enjoys architecting complex systems and hates workarounds and hotfix solutions. Easy solutions tend to do more harm in the long run.',
  },
  {
    id: 2,
    title: 'Tuan Phung',
    position: 'Frontend engineer',
    description: 'Will help you out with React and designs the best web.',
    linkedInUrl: 'https://www.phung.io/',
    imageUrl: tuanPhung,
    longDescription:
      'Frontend guru that will design and develop a beautiful and modern application - for example, like this web you see before you.',
  },
  {
    id: 3,
    title: 'Bronislav Svoboda',
    position: 'Senior C# Developer & Architect',
    description:
      'A little bit crazy cycling and sheer genius in the design of complex systems.',
    linkedInUrl: 'https://www.linkedin.com/in/bronislav-svoboda-6430a998/',
    imageUrl: bronislavSvoboda,
    longDescription:
      'The more complex system, the bigger the challenge. Bronislav enjoys working on complex solutions and brings his experience from other projects and modern top-end technologies. He is the technical person you want to have on your team when in doubt.',
  },
  {
    id: 4,
    title: 'Zbyněk Křípal',
    position: 'Full-stack C# Developer',
    description: 'Designs and develops any C# system from the bottom to the top.',
    linkedInUrl: 'https://www.linkedin.com/in/zbynek-kripal-b185a061/',
    imageUrl: zbynekKripal,
    longDescription:
      'Zbynek is a Full-stack C# developer with an interest in automation and virtualization. He loves to solve new challenges and to learn new technologies and designs. He believes that programming languages are just tools that should not limit a developer in problem-solving specific issues.',
  },
  {
    id: 5,
    title: 'Štěpán Doubal',
    position: 'QA & Automation Developer',
    description: 'Our beekeeper, crossfitter and guru on QA automatic testing.',
    linkedInUrl:
      'https://www.linkedin.com/in/%C5%A1t%C4%9Bp%C3%A1n-doubal-407303128/',
    imageUrl: stepanDoubal,
    longDescription:
      'Quality and automation is Stepan\'s main moto. Smoke tests, integration tests, or exploratory - these approaches are his bread and butter, and he eats developers for breakfast.',
  },
];

export default poeple;

import davidFogl from '../assets/people/davidFogl.jpeg';
import tuanPhung from '../assets/people/tuanPhung.jpeg';
import danielPetrasek from '../assets/people/danielPetrasek.jpeg';
import bronislavSvoboda from '../assets/people/bronislavSvoboda.jpeg';
import stepanDoubal from '../assets/people/stepanDoubal.jpeg';
import zbynekKripal from '../assets/people/zbynekKripal.jpeg';

const poeple = [
  {
    id: 0,
    title: 'David Fogl',
    position: 'Founder & CEO',
    description: 'David, da boss a shání nám dealy.',
    linkedInUrl: 'https://www.linkedin.com/in/david-fogl-46348042/',
    imageUrl: davidFogl,
    longDescription:
      'Vždy mě bavilo vytvářet nové nástroje a prototypy. Jakmile se najde nějaká činnost, co bych měl dělat manuálně a opakovat - hned hledám způsob jak to zautomatizovat a uvolnit si tak ruce na něco jiného. V poslední době se spíše orientuji na analytickou/konzultanskou činnost a hlavně na rozvoj Continera.',
  },
  {
    id: 1,
    title: 'Daniel Petrásek',
    position: 'Solution Architect & Developer',
    description:
      'Nastaví procesy, navrhne technologie a pomůže naimplementovat top řešení.',
    linkedInUrl: 'https://www.linkedin.com/in/daniel-petr%C3%A1sek-5a780763/',
    imageUrl: danielPetrasek,
    longDescription:
      'Backend developer, který se vyžívá v komplexních systémech a nesnáší workaroundy a plytká řešení. Krátkodobá řešení Vám v dlouhodobém měřítku spíše uškodí než pomohou.',
  },
  {
    id: 2,
    title: 'Tuan Phung',
    position: 'Frontend engineer',
    description: 'Pomůže ti s reactem a navrhne parádní web.',
    linkedInUrl: 'https://www.phung.io/',
    imageUrl: tuanPhung,
    longDescription:
      'Frontenďák co Vám navrhne webovou aplikaci, vytvoří PoCéčko i s backendem a pomůže rozjet krásný moderní web - jako je například tento.',
  },
  {
    id: 3,
    title: 'Bronislav Svoboda',
    position: 'Senior C# Developer & Architect',
    description:
      'Tak trochu cyklo blázen a naprostý génius v návrhu komplexních systémů.',
    linkedInUrl: 'https://www.linkedin.com/in/bronislav-svoboda-6430a998/',
    imageUrl: bronislavSvoboda,
    longDescription:
      'Čím komplikovanější systém, tím větší challenge. Rád pracuji na komplexních systémech a přináším do nich zkušenosti z předchozích projektů a moderních top-end technologiích.',
  },
  {
    id: 4,
    title: 'Zbyněk Křípal',
    position: 'Full-stack C# Developer',
    description: 'Navrhne a nakóduje celý systém v sí-šarpu.',
    linkedInUrl: 'https://www.linkedin.com/in/zbynek-kripal-b185a061/',
    imageUrl: zbynekKripal,
    longDescription:
      'Full-stack C# developer se zájmem o automatizace a virtualizace. Rád řeším problémy, které mě naučí nové věci a posunou zas o kousek dál. Věřím, že programovací jazyky jsou pouze nástroje, které by neměli limitovat developera k vyřešení problému.',
  },
  {
    id: 5,
    title: 'Štěpán Doubal',
    position: 'QA & Automation Developer',
    description: 'Náš včelař, crossfitter a guru přes automatické testy.',
    linkedInUrl:
      'https://www.linkedin.com/in/%C5%A1t%C4%9Bp%C3%A1n-doubal-407303128/',
    imageUrl: stepanDoubal,
    longDescription:
      'Kvalita a automatizace jsou moje hlavní moto. Smoke testy, integrační testy či exploratory - tohle vše je můj denní chleba a developery si dávám ke snídani.',
  },
];

export default poeple;
